import React from 'react'
import { navigate } from 'gatsby';


const AuditContainer = ({children, resetUser}) => {
    return (
        <div className="audit_container">
            <div className="audit_header">
                <div className="audit_button" onClick={()=>{navigate("/")}}>Home</div>
                <div className="audit_button" onClick={()=>{resetUser()}}>Reset User</div>
            </div>
            {children}
        </div>
    )
}

export default AuditContainer
